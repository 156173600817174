import setup from "../config";

async function handleErrors(res) {
  const { ok, status } = res;
  if (!ok) {
    if (status === 401) {
      localStorage.clear();
      location.href = "/login";
      throw Error("Token Inválido");
    }
    let json = await res.json();
    throw Error(
      (json.errors ? json.errors[0].detail : null) ||
        json.error ||
        json.message ||
        res.statusText
    );
  }
  return res;
}

function request(method, url, body, opts = {}) {
  const config = {
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  if (opts.isFile) {
    config.body = body;
  } else {
    config.headers["Content-Type"] = "application/json";
    config.body = JSON.stringify(body);
  }
  return fetch(url, config)
    .then(handleErrors)
    .then((res) => res.json());
}

function upload(url, inputName, inputFile, extraInputs) {
  let formData = new FormData();
  formData.append(inputName, inputFile, inputName);

  if (extraInputs) {
    Object.keys(extraInputs).forEach((key) => {
      formData.append(key, extraInputs[key]);
    });
  }

  let token = localStorage.getItem("token");

  const params = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  return fetch(url, params).then(async (res) => {
    if (res.ok) {
      return res.json();
    }
    throw await res.json();
  });
}

const get = (url) => request("GET", `${setup.api}${url}`);
const post = (url, body, opts = {}) =>
  request("POST", `${setup.api}${url}`, body, opts);
const put = (url, body) => request("PUT", `${setup.api}${url}`, body);
const del = (url, body) => request("DELETE", `${setup.api}${url}`, body);

function getToken(body) {
  const config = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return fetch(`${setup.api}/login/adm`, config)
    .then(handleErrors)
    .then((res) => res.json());
}

function uploadImage({ el, str, dim }) {
  let { 0: w, 1: h } = dim.split("x");
  let formData = new FormData();
  formData.append("w", w);
  formData.append("h", h);
  formData.append("img", el.files[0], el.files[0].name);
  return post(`/admin/upload${str}`, formData, { isFile: true });
}

function uploadImageCdn(url, inputFile, fileName) {
  let formData = new FormData();
  formData.append("image", inputFile, fileName);

  return fetch(url, {
    method: "post",
    body: formData,
  }).then(handleErrors);
}

function open({ url, newTab = true }) {
  const urlOpen = `${setup.api}${url}`;
  const targetTab = newTab ? "_blank" : "_self";

  fetch(urlOpen, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      var _url = window.URL.createObjectURL(blob);
      window.open(_url, targetTab).focus();
    })
    .catch((err) => {
      console.log(err);
    });
}

export {
  request,
  get,
  post,
  open,
  put,
  del,
  upload,
  getToken,
  uploadImage,
  uploadImageCdn,
};
