const config = {
  lang    : window.env.LANG || 'pt_br',
  api     : localStorage.getItem('api_url') || window.env.API_URL,
  cdn     : window.env.CDN_URL,
  apis    : window.env.APIS || [],
  gtmToken: process.env.NODE_ENV !== 'development' ? "GTM-PDT6TPR" : "GTM-5NXXVJ9",
  token   : function () {
    return localStorage.getItem('token')
  },
  websocket: {
    active: !!window.env.WS_HOST && !!window.env.WS_PORT && !!window.env.WS_KEY,
    encrypted: false,
    api_key: env.WS_KEY,
    options: {
      authEndpoint: (localStorage.getItem('api_url') || window.env.API_URL) + '/broadcasting/auth',
      httpHost: localStorage.getItem('ws_url') || window.env.WS_HOST,
      wsHost: localStorage.getItem('ws_url') || window.env.WS_HOST,
      httpPort: env.WS_PORT,
      httpsPort: env.WS_PORT,
      wsPort: env.WS_PORT,
      wssPort: env.WS_PORT,
      authTransport: 'ajax',
      disableStats: true,
      activityTimeout: 600000,
    },
  }
}

export default config